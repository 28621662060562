import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import UserStatsChart from "../UserStatsChart/UserStatsChart";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Dashboard.css";
import axios from "axios";

function Dashboard() {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    orderList();
  }, []);

  const orderList = () => {
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/list-order",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        if (data.data.result) {
          setOrders(data.data.list);
        } else {
          console.log("Data fetch failed");
        }
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  };
  const totalOrderAmount = Math.floor(
    orders.reduce((sum, order) => sum + order.od_amount, 0)
  );
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }
  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className="dashboard">
        <h2>Dashboard</h2>

        <Row className="mb-4">
          <Col xs={6} sm={4} md={3} lg={6}>
            <Card>
              <Card.Body>
                <h5>Sales</h5>
                <h3>₹{totalOrderAmount}</h3>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} sm={4} md={3} lg={6}>
            <Card>
              <Card.Body>
                <h5>Orders</h5>
                <h3>{orders.length}</h3>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <div className="transaction-history-container">
                  <h2 className="transaction-history-title">Orders History</h2>
                  <table className="transaction-history-table table table-striped table-hover">
                    <thead>
                      <tr>
                        <th className="transaction-history-header">
                          USER NAME
                        </th>
                        <th className="transaction-history-header">
                          DATE & TIME
                        </th>
                        <th className="transaction-history-header">PRODUCT</th>
                        <th className="transaction-history-header">AMOUNT</th>
                        <th className="transaction-history-header">
                          PAYMENT TYPE
                        </th>

                        <th className="transaction-history-header">
                          DELIVERY ADDRESS
                        </th>
                        <th className="transaction-history-header">
                          PAYMENT STATUS
                        </th>
                        <th className="transaction-history-header">
                          DELIVERY STATUS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.length > 0 ? (
                        orders?.map((transaction, index) => (
                          <tr key={index} className="transaction-history-row">
                            <td className="transaction-history-data">
                              <span>{transaction.user_name}</span>
                            </td>
                            <td className="transaction-history-data">
                              {formatDate(transaction.od_created_at)}
                            </td>
                            <td className="transaction-history-data">
                              {transaction.OrderProducts.map((item, index) => (
                                <>
                                  {item.name}-Qty {item.quantity}-Size{" "}
                                  {item.size},
                                </>
                              ))}
                            </td>
                            <td className="transaction-history-data">
                              ₹{parseFloat(transaction.od_amount).toFixed(2)}
                            </td>
                            <td className="transaction-history-data">
                              {transaction.od_payment_method}
                            </td>

                            <td className="transaction-history-data">
                              {transaction.billing_address}
                            </td>
                            <td className="transaction-history-data">
                              <span
                                className={`transaction-history-status ${
                                  transaction.od_payment_status === "Completed"
                                    ? "bg-success"
                                    : transaction.od_payment_status ===
                                      "Pending"
                                    ? "bg-warning"
                                    : "bg-danger"
                                }`}
                              >
                                {transaction.od_payment_status}
                              </span>
                            </td>
                            <td className="transaction-history-data">
                              {transaction.od_delivery_status}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8" className="text-center">
                            No orders found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
